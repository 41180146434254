.app {
  background-color: var(--primary-color) !important;
}

.app__admin-container {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 4.5rem 0rem 0rem 0rem;
  min-height: 100vh;
  overflow-y: hidden;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 6rem 0 0 0;
  }
}

.app__admin-categories-list {
  display: flex;
  width: 20vw;
  background: #ffffff;
  border-right: 1px solid #dcdcdc;
  flex-direction: column;
  max-height: 100vh;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    align-items: flex-start;
    input {
      margin: 1rem 1rem 0 0;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.app__admin-categories-data {
  display: flex;
  width: 85vw;
  border-right: 1px solid #dcdcdc;
  flex-direction: column;
  height: 90vh;
  padding: 0.5rem;
  margin-left: 1rem;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0;
    align-items: flex-start;
    input {
      margin: 1rem 1rem 0 0;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.app__admin-categories-mobile {
  display: none !important;
}

.app__admin-span{
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  padding: 2rem 0 1rem 1rem;
}

.app__admin-header{
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 8rem;
  background: var(--nav-bar-color);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: fixed;
  z-index: 2000;
  height: 4.5rem;

  @media screen and (max-width: 768px){
    padding: 0.5rem 2rem;
    height: 4.5rem;
  }
}

.app__admin-header-sm-logo{
  display: none;
  justify-content: flex-start;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  background-color: white;
  border-radius: 50%;


  a,img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.app__admin-header-logo {
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;


  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 501px) {
    display: flex;
  }
}

.app__admin-category {
  display: flex;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.3rem;
    padding: 1rem 0 1rem 0;
  
    color: #333333;
  }

  li {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    padding: 1rem 0 1rem 1rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    color: #333333;
    margin: 1rem 0;
    background-color: var(--primary-color);
    border-radius: 5px;
  }
}


ul {
  list-style-type: none;
  width: 100%
}


button {
  background-color: var(--green-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  height: 60%;
}


// DATA TABLE 
.container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 90vh;

}

h2 {
  font-size: 26px;
  margin: 20px 0;
  text-align: center;
  small {
    font-size: 0.5em;
  }
}

#app__admin-search-catalog-container {
  height: 3.5rem;
  width: 100%;
  border: 0px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding: 0;

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    margin: 0;
    margin-bottom: 1rem;
    padding-left: 1rem;
    font-size: 1rem;
    border-radius: 5px;
  }
}

.responsive-table {
  max-height: 75vh;
  overflow-y: auto;
  li {
    border-radius: 3px;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
  }
  .table-header {
    background-color: var(--nav-bar-color);
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: white
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
  }
  .col-1 {
    width: 25%
  }
  .col-2 {
    width: 40%;
    word-break: break-all;
    margin-right: 1rem;
  }
  .col-3 {
    width: 15%;
    word-break: break-all;
    margin-right: 1rem;
  }
  .col-4 {
    width: 10%;
  }
  .col-5 {
    width: 10%;
  }
  
  @media all and (max-width: 767px) {
    .table-header {
      display: none;
    }
    .table-row{
      
    }
    li {
      display: block;
    }
    .col {
      
      flex-basis: 100%;
      
    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
}

.responsive-table::-webkit-scrollbar {
  scrollbar-width: 0.1rem;
}

/* Hide scrollbar for IE, Edge and Firefox */
.responsive-table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.btn-edit {
  background-color: #265e28;
  padding: 0.5rem;
  margin: 0;
  width: 5rem;
  height: 2.5rem;
  justify-content: flex-end;
}

.btn-remove {
  background-color: #ae2020;
  padding: 0.5rem;
  margin: 0;
  width: 5rem;
  height: 2.5rem;
  justify-content: flex-end;
}


.header {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;  
  button {
    margin: 0;
    width: fit-content;
    position: relative;
    left: 0
  }
}

.logout-btn {
  margin: 0;
  width: fit-content;
  position: relative;
  left: 0;
  margin-right: 0.5rem;
}