html, body{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}


#root {
  height: 100%;
  width: 100%;
}

.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
  height: 100%;
  width: 100%;
}

.app__container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}


.app__wrapper {
  flex: 1 1;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding-top: 4.5rem;
  }
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  justify-content: center;

  @media screen and (min-width: 2000px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1rem;
  }
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
}

.app__navigation {
  position: absolute;

  padding: 1rem;
  z-index: 200;
  height: 100vh;
  left: 95%;
  display: flex;

  .app__navigation-flexContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
      width: 13px;
      height: 13px;
    }
  }
}

@media screen and (max-width: 780px) {
  .app__navigation, .copyright {
    display: none;
  }
}

.MuiPaper-root{
  z-index: 2000;
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40vh;
  left: 40vw;
}