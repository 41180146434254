/* Slideshow */

.slideshow {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    height: 75%;
    padding-top: 2rem;
  }
  }

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
  transition-delay: ease-in-out 1000ms;
  height: 85%;
}

.slide {
  display: inline-block;

  height: 100%;
  width: 100%;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 0.9em;
  width: 0.9rem;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;

  @media screen and (max-height: 700px) {
    height: 0.7rem;
    width: 0.7rem;
  }
}

.slideshowDot.active {
  background-color: var(--nav-bar-color);
}

#slide-service-item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    height: 100%;

    svg {
      z-index: 4;
      font-size: 2rem;
    }

    span {
        z-index: 100;
        font-size: 1.8rem;
        font-weight: 700;
        word-break: normal;
        white-space: normal;
        color: white
      }
}

.app__services-list-img {
    @media screen and (max-width: 768px) {
      display: block;
      z-index: 0;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
      position: absolute;
    }
}


.app__services-bg-container {
  position: absolute;
  background: var(--nav-bar-color);
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 0.6;
}
  