.app__store-container {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 8rem 2rem 1rem 8rem;
  min-height: 100vh;
  background-color: var(--primary-color) !important;;

  @media screen and (min-width: 1600px) {
    padding: 8rem 6rem 1rem 8rem;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 4.5rem 0 0 0;
  }
}

.app__store-products-filters-container {
  display: flex;
  width: 100%;
  height: 95%;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100vw;
  }
}

.app__store-span {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  padding: 2rem 0 1rem 3rem;

  @media screen and (min-width: 1600px) {
    font-size: 2rem;
    height: 5rem;
  }
}

.app__store-filters {
  display: flex;
  width: 20%;
  background: #ffffff;
  border-right: 1px solid #dcdcdc;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 3rem;
    align-items: flex-start;
    input {
      margin: 1rem 1rem 0 0;
    }
  }

  @media screen and (min-width: 1600px) {
    
    label {
      font-size: 1.2rem;
      height: 3rem;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.app__store-filters-mobile{
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    border-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    height: 6vh;
  }
}


.app__store-products {
  display: flex;
  height: 100%;
  width: 80%;
  padding: 0.2rem;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.app__store-product-item {
  display: flex;
  height: 55vh !important;
  width: 30%;
  margin: 1.5%;
  flex-direction: column;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 8px 32px 0 rgba(150, 156, 243, 0.37);
  }

  *{
    cursor: pointer;
  }

  @media screen and (min-width: 1600px) {
    height: 750px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 55vh !important;
    margin: 1rem !important;
  }
}

.app__store-product-item-img {
  width: 100%;
  height: 70% !important;
  background-color: rgb(222, 222, 222);
  border-radius: 8px;

  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.app__store-product-item-info {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  span {
    font-weight: 600;
    font-size: 1rem !important;
    line-height: 20px;
    height: 30%;
    padding: 1rem;
    color: #333333;

    @media screen and (min-width: 1600px) {
      padding-top: 1.5rem;
      font-size: 1.2rem !important;
    }
  }
  p {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 17px;
    height: 70%;
    padding: 1rem;
    color: #333333;

    @media screen and (min-width: 1600px) {
      padding: 1rem 0.5rem 0.5rem 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    height: auto !important;
    span{
      font-size: 1.15rem!important;
      height: auto !important;
      padding: 0.5rem 1rem;
    }
  }
}

.app__store-product-item-span {
  width: 100%;
  padding: 0.5rem;
}

.app__store-product-item-p {
  width: 100%;
  padding: 0.5rem;
}

.app__store-no-products {
  display: flex;
  margin: auto;
}

.app__store-filter {
  display: flex;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.3rem;
    padding: 1rem 0 1rem 0;
  
    color: #333333;

    @media screen and (min-width: 1600px) {
      font-size: 1.3rem;
      height: 4rem;
    }
  }

  li {
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    padding: 0.4rem 0;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #333333;
    cursor: pointer;
    height: 2rem;

    @media screen and (min-width: 1600px) {
      font-size: 1rem;
      height: 2.5rem;
    }
  }
}


// SEARCH BAR
.app__storeSearchBar {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 6rem 0.5rem 8rem;
  background: var(--nav-bar-color);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: fixed;
  z-index: 2000;
  height: 4.5rem;

  @media screen and (min-width: 1600px) {
    height: 6rem;
    padding: 0.5rem 10rem 0.5rem 8rem;
  }

  @media screen and (max-width: 768px){
    padding: 0.5rem 2rem;
    height: 4.5rem;
    overflow-x: hidden;
  }
}

.app__storeSearchBar-sm-logo{
  display: none;
  justify-content: flex-start;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  background-color: white;
  border-radius: 50%;


  a,img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.app__storeSearchBar-logo {
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;


  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 501px) {
    display: flex;
  }
  
  @media screen and (min-width: 1600px) {
    height: 65%;

    a, img {
      height: 100%;
    }
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

.app__storeSearchBar-bar {
  flex-grow: 2;
  justify-content: center;
  height: 80%;
  display: flex;

  input {
    height: 100%;
    border: 0;
    margin-right: 2rem;
    border-radius: 10px;
    font-family: var(--font-base), 'FontAwesome';
    font-size: 1rem;
    font-weight: thin !important;
    padding: 1rem;

    @media screen and (min-width: 1600px) {
      font-size: 1.2rem;
    }
  }
}

.app__products-search-bar-desktop {
  display: block;
  width: 50%;

  @media screen and (max-width: 768px){
    display: none;
  }
}

.app__products-search-bar-mobile{
  display: none;
  @media screen and (max-width: 768px){
    display: block;
    margin: 0 !important;
    width: 80% !important;
  }
}

.app__storeSearchBar-back {
  display: flex;
  color: white;
  cursor: pointer;

  @media screen and (min-width: 768px){
    display: none;
  }
}

.app__store-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 2rem;
  width: 50%;
  height: 3rem;
  background: transparent;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3rem;
  color: #333333;

  @media screen and (max-width: 768px) {
    display: none;
  }

  div {
    height: 3rem;
    width: 3rem;
    background-color: #ffffff;
    margin: 0 0.5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.app__store-navbar-filter {
  width: 100vw;
  height: 6vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  overflow: scroll;
  box-shadow: 1px 1px 2px 1px #888888;
  padding-right: 1rem;

  p {
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.3rem;
    color: #333333;
    margin-left: 0.5rem;
  }

  svg {
    width: 20px;
    height: 20px;
    color: var(--nav-bar-color);
    border: 0px;
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    padding: 5rem 0.2rem 0rem 1.5rem;

    width: 100vw;
    height: 100vh;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }


    /* top box shadow */
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 4vh;
      height: 4vh;
      color: var(--nav-bar-color);
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding-right: 1rem;
      height: 85vh;
      width: 100%;
      overflow-y: scroll;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        height: auto;
      }

      li {
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        height: 2.5rem;
        margin: 0.2rem 0;

        a {
          color: var(--gray-color);
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;

          transition: all .5s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }

      label {
        margin: 1rem;
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;

        input {
          margin-right: 1rem;
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}

#app__store-filters-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 10vh;
  position: relative;
  padding: 0;
  padding-right: 2rem;
  flex-direction: row;
  box-shadow: none;
  min-height: 10vh;
}

#app__store-filters-clear {
  background-color: var(--orange);
}

#app__store-filters-mobile-categories-header {
  flex-direction: row;
  width: 100%;
  height: 3rem;
  display: flex;
  position: relative;
  padding: 0rem 1rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
}

#app__store-filters-mobile-brands-header {
  flex-direction: row;
  width: 100%;
  height: 3rem;
  display: flex;
  position: relative;
  padding: 0rem 1rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
}

#app__store-filters-mobile-subCategories-header {
  flex-direction: row;
  width: 100%;
  height: 3rem;
  display: flex;
  position: relative;
  padding: 0rem 1rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
}

#app__store-filter-entries {
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0rem 2.5rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
  height: auto;
}

#app__store-filters-mobile-li {
  height: auto;
  border-bottom: 1px solid #e5e5e5;
}

.app__store-load-more-btn-container {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-bottom: 2rem;
}

.app__store-load-more-btn {
  width: auto;
  height: 100%
}

.spinner-border {
  width: 10vw;
  height: 10vh;
  left: 50vw;
  top: 50vh;
  position: absolute;

  @media screen and (max-width: 768px) {
    width: 15vw;
    height: 15vh;
    left: 42.5vw;
  }
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.visually-hidden {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  animation: blinker 1.2s linear infinite;
  color: var(--nav-bar-color)
}