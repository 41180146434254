.app__storeSearchBar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 8rem;
  background: var(--nav-bar-color);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: fixed;
  z-index: 2000;
  height: 4.5rem;

  @media screen and (max-width: 768px){
    padding: 0.5rem 2rem;
    height: 4.5rem;
  }
}

.app__storeSearchBar-sm-logo{
  display: none;
  justify-content: flex-start;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  background-color: white;
  border-radius: 50%;


  a,img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.app__storeSearchBar-logo {
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;


  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 501px) {
    display: flex;
  }
}

.app__storeSearchBar-bar {
  flex-grow: 2;
  justify-content: center;
  height: 80%;
  display: flex;

  input {
    width: 50%;
    height: 100%;
    border: 0;
    margin-right: 2rem;
    border-radius: 10px;
    font-family: var(--font-base), 'FontAwesome';
    font-size: 1rem;
    font-weight: thin !important;
    padding: 1rem;
  
    @media screen and (max-width: 768px){
      margin: 0;
    }
  }
}

