.app__pageNotFound-container{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.app_pageNotFound-message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 2;
    padding: 0rem 1rem;

    a {
        text-decoration: none;
        color: var(--orange);
        font-size: 1rem;
        font-weight: 600;
        margin-top: 1rem;
        cursor: pointer;
    }
}

.app__pageNotFound-message-code {
    font-size: 4rem;
}


.app__pageNotFound-message-txt {
    font-size: 1.5rem;
    margin-top: 0.8rem;
    text-align: center;
}