#Servicios {
  background-color: var(--nav-bar-color);
  @media screen and (max-width: 768px) {
    background: #EDF2F8;
  }
}

.app__services-bg-img-container{
  justify-content: flex-start;
  height: 60vh;
  width: 100%;
  background: var(--nav-bar-color);
  opacity: 0.5;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 768px){
    display: none;
  }
}

.app__services {
  width: 100%;

  @media screen and (min-width: 800px){
    flex-direction: column;
  }
}

.app__services-container {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 0rem 4rem;
  position: relative;
  top: -40vh;

  @media screen and (min-width: 1600px){
    top: -30vh;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    height: 100vh;
    padding: 3rem 0 0 0;
    top: 0;
    position: static;
  }
  h4 {
    font-size: 1rem;
    font-weight: 200;
  }
}

.app__services-title{
  * {
    color: var(--white-color)!important;
    @media screen and (max-width: 768px){
      color: var(--black-color)!important;
    }
  }
}

.app__services-list {
  display: flex;
  justify-content: space-around;
  // background: var(--nav-bar-color);
  padding-top: 1rem;
  border-radius: 10px;
  width: 90%;
  margin: auto;
  margin-top: 8rem;
  
  @media screen and (max-width: 1600px){
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.app__services-list-item {
  padding: 1rem;
  width: 15rem;
  height: 15rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1300px) {
    padding: 1rem;
    width: 18rem;
    height: 18rem;
    svg {
      margin-top: 2rem;
      height: 3.5rem;
      width: 6rem;
      color: var(--nav-bar-color)
    }

    &:hover {
      *{
        display: none;
      }
      p {
        display: block;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    width: 20rem;
    height: 20rem;
    svg {
      height: 6rem;
      width: 12rem;
    }

    &:hover {
      *{
        display: none;
      }
      p {
        display: block;
        font-size: 1.3rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 2rem;
    border-radius: 0px;
    background-color: transparent;

    span {
      z-index: 1;
      font-size: 2rem;
      font-weight: 700;
    }

    p {
      z-index: 1;
      font-size: 1.1rem;
      padding: 2rem 0.5rem;
    }
  }

  span {
    padding: 1rem 0;
    font-size: 1.5rem;
    font-weight: bold;
    display: block;
  }

  p {
    display: none;
  }

  background-color: #d9d9d9;
}

