/*---------------SLIDE ANIMATION ---------------*/

@-webkit-keyframes slide_animation {
  0% {left: 0px;}
  10% {left: 0px;}
  20% {left: 1200px;}
  30% {left: 1200px;}
  40% {left: 1200px;}
  50% {left: 2400px;}
  60% {left: 1200px;}
  70% {left: 1200px;}
  80% {left: 0px;}
  80% {left: 0px;}
  90% {left: 0px;}
  100% {left: 0px;}
}

#Nosotros{
  height: 100vh;
  background-color: #F5F3ED;
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 0 4rem;
  @media screen and (max-width: 768px) {
    padding: 0.5rem 0 0 0;
    height: 100%;
  }
}

.app__about-us {
  @media screen and (max-width: 768px) {
    background: var(--mobile-about-bg)
  }
}

.app__about-info {
  width: 73%;
  display: flex;
  float: left;
  flex-direction: column;
  padding: 8rem 1rem 0 1rem;
  height: 100vh;

  h2 {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 1400px) {
    padding: 3.5rem 1rem 0 1rem;
  }
  @media screen and (min-width: 1600px) {
    padding: 8rem 4rem 0 4rem;
  }
  @media screen and (max-width: 768px){
    width: 100%;
    align-items: center;
    padding: 0;
    text-align: center;
  }
}

.app__about-img {
  width: 27%;
  display: flex;
  height: 85vh;
  @media screen and (max-width: 768px){
    display: none;
  }
  img {
    object-fit: cover;
    width: 120%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.app__enterprise-intro-box {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;

  span {
    font-size: 1.2rem;
    padding: 0.1rem 0;
  }

  @media screen and (max-width: 1400px) {
    padding: 1rem 2rem;
    p {
      font-size: 0.9rem;
    }
    span {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 768px){
    padding: 1.5rem 2rem;
  }
}

.app__enterprise-intro-text {
  padding-bottom: 0.5rem;

  @media screen and (min-width: 1600px) {
    font-size: 1.2rem;
  }
}

.app__enterprise-intro-box-mision-vision {
  display: block;
  padding: 0.5rem 0;
  font-style: italic;

  @media screen and (max-width: 768px){
    display: none;
  }

  @media screen and (min-width: 1600px) {
    font-size: 1.1rem;
  }
}


.app__enterprise-details {
  display: flex;
  justify-content: space-between;
  margin-top: 0rem;
  width: 100%;

  div {
    padding: 1rem;
    width: 10rem;
    height: 10rem;
    align-items: center;
    justify-content: center;

    span {
      font-size: 0.8em
    }

    p {
      font-size: 0.5em
    }
    @media screen and (min-width: 769px) and (max-width: 1300px) {
      height: 6rem;

      div {
        display: flex;
        height: 6rem;
        justify-content: flex-start;
        padding: 0 1rem;
        width: 7rem;
      }
    }

    @media screen and (max-width: 1400px) {
      height: 8.5rem;

      div {
        display: flex;
        height: 8.5rem;
        justify-content: flex-start;
        padding: 0 1rem;
        width: 9rem;
      }
    }

    @media screen and (min-width: 1401px) {
      padding: 1rem;
      width: 16.5rem;
      height: 16.5rem;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      span {
        font-size: 1em
      }
  
  
      p {
        font-size: 0.8em
      }
    }

    @media screen and (min-width: 1600px) {
      width: 19rem;
      height: 19rem;
      margin-top: 4rem;
      align-items: center;
      justify-content: center;
      display: flex;
      span {
        font-size: 1.5em
      }

      p {
        font-size: 1em
      }
    }

  }

  span {
    text-align: center;
  }

  ul {
    list-style: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }

}

.app__enterprise-details-img {
  display: none;

  @media screen and (max-width: 768px){
    display: block;
    position: absolute;
    width: 100%;
    border-radius: 0px
  }
}

.app__enterprise-value {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 13rem;
  height: 13rem;
  border-radius: 10px;

  @media screen and (min-width: 769px) and (max-height: 1299px) {
    padding: 1rem;
    width: 9rem;
    height: 9rem;
    img {
      height: 6rem;
      width: 6rem;
    }
    span {
      font-size: 1rem !important;
      font-weight: 500 !important;
    }
  }

  @media screen and (min-width: 1300px) and (max-height: 700px) {
    padding: 1rem;
    width: 12rem;
    height: 12rem;
    img {
      height: 7rem;
      width: 7rem;
    }
    span {
      font-size: 1rem !important;
      font-weight: 500 !important;
    }
  }

  @media screen and (min-width: 1401px) {
    padding: 1rem;
    width: 15rem;
    height: 15rem;
    img {
      height: 8.5rem;
      width: 8.5rem;
    }
    span {
      font-size: 1.3rem !important;
      font-weight: 500 !important;
    }
  }

  @media screen and (min-width: 1600px) {
    width: 18rem;
    height: 18rem;
    img {
      height: 10rem;
      width: 10rem;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 2rem;
    border-radius: 0px;
    background-color: transparent;

    span {
      z-index: 1;
      font-size: 2rem;
      font-weight: 700;
    }

    p {
      z-index: 1;
      font-size: 1.1rem;
      padding: 2rem 0.5rem;
    }
  }

  span {
    font-size: 1.5rem;
    font-weight: bold;
    display: block;
  }

  p {
    display: none;
    text-align: center;
  }
}
#app__enterprise-vision {
  display: flex;
  flex-direction: column;
}


