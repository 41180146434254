.app {
    background-color: var(--nav-bar-color);
}

.app__login-sm-logo {
    display: flex;
    height: 10rem;
    width: 10rem;
    margin-top: 2rem;
}

.app__login-container {
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 5vh;
    position: relative;
    background-color: var(--primary-color);
    border-radius: 5px;
    padding: 0.2rem;

    @media screen and (max-width: 768px) {
        width: 80vw;
    }

    @media screen and (min-width: 1600px) {
        width: 20vw;
    }
        
}

.app__login-form-container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
}


.app__login-input {
    width: 80%;
    display: flex;
    flex-direction: row;
    height: 4rem;
    padding: 0.5rem 0;
    margin: auto;
    margin-bottom: 1rem;

    input {
        width: 100%;
        border: 0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 1rem;
    }
}

.app_login-input-svg{
    width: 3.5rem;
    padding: 0.2rem;
    background-color: var(--green-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    svg {
        width: 100%;
        height: 100%;
        padding: 0.7rem;
        color: var(--white-color);
    }
}

.signin-btn {
    width: 80%;
    height: 3rem;
    border: 0;
    border-radius: 10px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--green-color);
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin: 2rem 0;
    position: relative;
    left: 10%;
}


.form-login {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}

.login-text {
    font-size: 2rem;
    display: flex !important;
    padding: 2rem 1rem;
}

.form-p-error {
    color:#ae2020;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    padding-left: 3rem;
  }
  