/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 35px;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none; 

  @media screen and (min-width: 1600px) {
    right: 60px;
    bottom: 60px;
  }

  @media screen and (max-width: 380px) {
    display: static;
    width: 40px;
    height: 40px;
    bottom: 30px;
    right: 25px;
    font-size: 28px;
    z-index: 3000;
  }

  @media screen and (max-width: 767px) and (min-width: 381px) {
    display: static;
    width: 45px;
    height: 45px;
    bottom: 60px;
    right: 25px;
    font-size: 28px;
    z-index: 3000;
  }
}