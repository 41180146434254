.app__products-main {
  background: var(--mobile-about-bg);
}

.app__products {
  flex: 1;
  width: 100%;
  flex-direction: column;
  display: flex;
  padding: 0 3rem;

  @media screen and (min-width: 1600px) {
    padding: 0 8rem;
  }
    
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding: 0 1.1rem;
  }
  h4 {
    font-size: 1rem;
    font-weight: 200;
  }
}

.app__products-title{
  padding-top: 6.5rem;
  height: 25vh;

  @media screen and (min-width: 1600px) {
    padding-top: 10rem;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding-top: 1.5rem;
    
    h1 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1rem;
    }
  }
}

.app__products-search-container {
  height: 10vh;
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
  padding: 1rem;
  background-color: var(--white-color);
  border-radius: 10px;
  width: 100%;

  input {
    padding: 1rem;
    flex-grow: 10;
    font-size: 1rem;
  }
 
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  @media screen and (min-width: 1700px) {
    height: 8vh;
    margin-top: 3rem;
  }
    
  @media screen and (max-width: 768px){
    flex-direction: column;
    padding: 0;
    background-color: transparent;
    height: auto;
    margin: 0;
    padding: 0;

    input {
      width: 100%;
      padding: 1rem;
      font-size: 0.8rem;
    }

    button {
      margin: 1rem 0;
      width: 46%;
      height: 80%;
    }
  }
}

.app__products-search-bar{
  border: 0;
  margin-right: 2rem;
  border-radius: 10px;
  font-family: var(--font-base), 'FontAwesome';
  font-size: 1.2rem;
  font-weight: thin !important;

  @media screen and (max-width: 768px){
    margin: 0;
  }
}
.app__products-search-buttons-container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px){
   height: 3.5rem;
  }
}

.app__products-search-button {
  border: 0;
  border-radius: 10px;
  background-color: var(--green-color);
  color: white;
  margin: 0 0.5rem;
  min-width: 15vh;
  height: 100% !important;
}

#app__products-search-show-all-btn {
  background-color: #ca6a42;
}

.app__products-list {
  display: flex;
  border-radius: 10px;
  justify-content: space-around;
  margin-top: 4rem;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--white-color);

  * {
    border-radius: 10px;
    height: 100%;
  }

  @media screen and (max-width: 768px){
    margin-top: 3rem;
    min-height: 33vh;
    background-color: var(--white-color);

    * {
      max-height: 100%;    
    }
  }

  @media screen and (min-width: 1700px) {
    height: 45vh;
  }
}

.app__products-carousel-desktop {
  display: block;
  max-height: 35vh;
  
  @media screen and (min-width: 1700px) {
    max-height: 45vh;
  }

  * {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

#app__products-carousel-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.app__products-carousel-img {
  width: 100%;
  object-fit: cover;
}

form {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}