.phone_float {
  position: fixed;
  background-color: var(--orange);
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none; 
  display: none;

  @media screen and (max-width: 380px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 40px;
    height: 40px;
    bottom:80px;
    right: 25px;
    font-size: 28px;
    z-index: 3000;
  }

  @media screen and (max-width: 767px) and (min-width: 381px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 45px;
    height: 45px;
    bottom: 115px;
    right: 25px;
    font-size: 25px;
    z-index: 3000;
  }
}

