.app__customers-main {
  background: #EDF2F8;
}
.app__customers {
  flex: 1;
  width: 100%;
  flex-direction: column;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  padding-top: 2rem;

  @media screen and (max-width: 768px) {
    padding-top: 0.5rem;
  }
}

.app__customers-title {
  padding-top: 2.5rem;
  h4 {
    font-size: 1rem;
    font-weight: 200;
  }

  @media screen and (max-width: 768px) {
    padding-top: 3rem;
  }

  @media screen and (min-width: 1600px) {
    padding-top: 6rem;
  }
}

.app__customers-item-mobile-customer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0.5rem;
    border-radius: 5px;

    img {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 768px) {
      height: 50% !important;
    }
}


.app__customers-customers {
  width: 90%;
  flex-wrap: wrap;
  margin: auto;
  height: 50vh;

  div {
    height: 100% !important;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    height: 50vh;
    margin: auto;
  }

  @media screen and (min-width: 1700px) {
    height: 55vh;
  }
}

.carousel-initialized {
  @media screen and (max-width: 768px) {
    div{
      height: 100% !important;
      display: flex !important;
      justify-content: center;
    }
  }
}