.app {
  background-color: var(--nav-bar-color);
}

.app__form-category-form-container {
  width: 36vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 0.2rem;

  @media screen and (max-width: 768px) {
    width: 80vw;
  }
  @media screen and (min-width: 1800px) {
    width: 30vw;
  }
}

.app__form-category-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}


.css-efvcc7-MuiPaper-root-MuiAlert-root{
  z-index: 2500;
  width: 30vw;
  margin-top: 46vh;
  height: 8vh;
  margin-left: 35vw;
  position: fixed;

  * {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.app__category-header {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 8rem;
  background: var(--nav-bar-color);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: fixed;
  z-index: 2000;
  height: 4.5rem;

  @media screen and (max-width: 768px) {
    padding: 0.5rem 2rem;
    height: 4.5rem;
  }
}

.app__category-header-sm-logo{
  display: none;
  justify-content: flex-start;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  background-color: white;
  border-radius: 50%;

  a,
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.app__category-header-logo {
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 501px) {
    display: flex;
  }
}

.form-category-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-form {
    width: 100%;
    display: flex;
    flex-direction: column;

    label {
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 1rem;
        padding-left: 0.1rem;
    
        label {
            font-weight: 200;
            font-size: 0.8rem;
        }
    }
}

.form-category-input {
    width: 100%;
    height: 3rem;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
}

.form-category-textarea {
    border: none;
    border-radius: 5px;
    height: 14rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: var(--font-base);
    padding: 1.2rem;
    font-size: 1.1rem;
    resize: none;
}

.form-category-btn-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 3rem;

    button {
        width: 46%;
        height: 100%;
    }
}

#category-cancel-btn {
 background-color: #ae2020;   
}

.form-p-error {
  color:#ae2020;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}