.app__navbar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 8rem;
  background: var(--nav-bar-color);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: fixed;
  z-index: 2000;
  height: 4.5rem;

  @media screen and (max-width: 768px){
    padding: 0.5rem 2rem;
    height: 4.5rem;
  }

  @media screen and (min-width: 1600px) {
    height: 6rem;

    
  }
}

.app__navbar-sm-logo{
  display: none;
  justify-content: flex-start;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  background-color: white;
  border-radius: 50%;


  a,img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.app__navbar-logo {
  display: none;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0rem;


  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 501px) {
    display: flex;
  }

  @media screen and (min-width: 1600px) {
    height: 65%;
    padding: 0;

    a,img {
      height: 100%;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;

  li {

    @media screen and (min-width: 1500px) {
      font-size: 0.9rem;
    }

    margin: 0 1rem;
    cursor: pointer;

    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;

      margin-bottom: 5px;
    }

    a {
      color: var(--white-color);
      text-decoration: none;
      flex-direction: column;

      text-transform: uppercase;
      font-weight: 500;

      transition: all .5s ease-in-out;

      &:hover {
        color: var(--white-color);
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}


#app__navbar-link-store {
  height: 2rem;
  width: 2rem;
  background-color: var(--white-color);
  padding: 0.1rem;
  border-radius: 10px;

  svg {
    padding: 0.2rem;
    width: 100%;
    height: 100%;
    color: var(--green-color);
  }

  svg:hover {
    color: var(--white-color);
  }

  @media screen and (min-width: 1600px) {
    height: 2.2rem;
    width: 2.2rem;
  }
}

#app__navbar-link-store:hover {
  background-color: var(--green-color);
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

#app__navbar-mobile-Tienda {
  animation: blinker 1.2s linear infinite;
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;


  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    padding: 1rem;

    width: 80vw;
    height: 100vh;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;


    /* top box shadow */
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;

        a {
          color: var(--gray-color);
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;

          transition: all .5s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}

