@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --nav-bar-color: #1B2A3E;
  --about-us-color: #F5F5F5;
  --green-color: #39756F;
  --facebook-color: #1877f2;
  --instagram-bg: url('./assets/Instagram-logo-background.jpeg');
  --mobile-about-bg: #F5F3ED;
  --contact-card-bg: #DBE5F0;
  --orange: #D2712A;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
