.app__header {
  flex: 1;
  width: 100%;
  flex-direction: row;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-container {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: var(--nav-bar-color);
  z-index: 1000;
    img {
      width: 100%;
      height: 100%;
    }
}

.app__header-container-mobile-img {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh; 
  overflow: hidden;

  @media screen and (max-width: 768px) {
    display: none;
  }
}
.video-container video {

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: 100vw;
  height: 100vh;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  object-fit: cover;
}


.app__title__header {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 200;
  left: 0;
  // background-image: linear-gradient(to right, var(--nav-bar-color), transparent);

  @media screen and (max-width: 768px) {
    background-image: none;
    justify-content: center;
    width: 100%;
    display: flex;
  }
}

.app__title__header-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--white-color);
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  
  h1 {
    @media screen and (max-width: 1600px) {
      font-size: 2rem;
    }
  }
  img{
    max-width: 15rem;
    max-height: 10rem;
    opacity: 0.9;
    margin-top: 1rem;
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5rem 1rem;
    h1 {
      padding: 1rem 3rem;
      font-size: 1.4rem;
      text-align: center;
    }
    img {
      max-width: 12rem;
      max-height: 10rem;
      opacity: 0.9;
    }
  }
}

.app__header-container-bg{
  position: absolute;
  background: var(--nav-bar-color);
  width: 100%;
  height: 100vh;
  opacity: 0.5;

  @media screen and (max-width: 768px) {
    opacity: 0.35;
  }
}
