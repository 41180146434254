#Contacto {
  @media screen and (max-width: 768px) {
    background: var(--mobile-about-bg);
  }
}

.app__contact {
  flex: 1;
  width: 100%;
  flex-direction: column;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app__contact-map-div {
  padding: 0.5rem 1rem;
  flex-grow: 15;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px){
    display: none;
  }
}

.google-map-code {
  width: 100%;
  position: relative;
  iframe {
    width: 100%;
    padding: 0;
  }
}

.app__contact-div {
  flex-grow: 12;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  justify-content: center;
  align-items: center;
  max-width: 40%;

  @media screen and (max-width: 768px){
    max-width: 100%;
    padding: 0;
  }
}

// .app__contact-cards {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 1rem 2rem;

//   .app__contact-card {
//     min-width: 200px;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;
//     width: 40%;

//     margin: 1rem 1rem;
//     padding: 1rem;
//     border-radius: 10px;
//     cursor: pointer;
//     background-color: var(--contact-card-bg);

//     transition: all 0.3s ease-in-out;

//     img {
//       width: 40px;
//       height: 40px;
//       margin: 0 0.7rem;
//     }

//     p {
//       font-weight: 500;
//       padding-right: 1rem;
//     }
//     a {
//       text-decoration: none;
//       font-weight: 500;
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//     }
//     &:hover {
//       box-shadow: 0 0 25px #fef4f5;
//     }

//     @media screen and (max-width: 768px) {
//       width: 30%;
//       display: flex;
//       justify-content: space-evenly;
//       align-items: center;
//       margin: 0.5rem 0 0 0;
//       min-width: 100px;
//       height: 7vh;
//       padding: 0;
      
//       a {
//         p {
//           display: none;
//         }
//       }
//     }
//   }

//   @media screen and (max-width: 768px) {
//     width: 100%;
//     display: flex;
//     justify-content: space-evenly;
//     align-items: center;
//     margin: 1rem;
//   }
// }

// .app__contact-cards .app__contact-card:last-child:hover {
//   box-shadow: 0 0 25px #f2f7fb;
// }

.app__contact-form {
  flex-direction: column;
  width: 85%;
  margin-bottom: 1rem;

  div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: var(--contact-card-bg);
      height: 3.5rem;
      font-size: 1rem;
      font-family: var(--font-base);
      color: black;
      outline: none;
    }

    textarea {
      height: 170px;

      @media screen and (min-width: 1500px) {
        height: 300px;

      }
    }

    &:hover {
      box-shadow: 0 0 25px var(--primary-color);
    }
  }

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: var(--green-color);

    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);
    font-size: 1rem;

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;
    
    &:disabled{
      pointer-events: none;
      opacity: 0.8;
    }

    &:hover {
      background-color: white;
      color: var(--green-color);
      border: 1px solid var(--green-color);
    }
  }


  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.app__contact-map-contact-container {
  width: 100%;
  padding: 8rem 3rem 0 7rem;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px){
    padding: 0 1rem;
    display: flex;
    flex-direction: column-reverse;
  }
}

.app__contact-footer {
  background-color: var(--nav-bar-color);
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px){
    height: 10vh;
  }
}

.app__footer-logo{
  flex-grow: 2;
  padding: 1rem 0 1rem 8rem;

  @media screen and (max-width: 768px){
    padding: 0.5rem 0 0.5rem 2rem;
    display: flex;
    align-items: center;
  }
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  flex-grow: 2;
  p {
    font-size: 1rem;
    color: var(--white-color);
  }   
  
  @media screen and (max-width: 768px){
    display: none;
  }
}

.app__social {
  flex-grow: 2;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 8rem 1rem 0;
  div {
    padding: 0.5rem;
    font-size: 1.5rem;
    color: var(--white-color);
    align-items: center;
  }
  a:visited { 
    text-decoration: none; 
    color: white; 
  }
  svg {
    width: 100%;
    height: 100%;
    display: flex;
  }

  @media screen and (max-width: 768px){
    padding: 0.5rem 0 0.5rem 2rem;
  }
}

#app__social-facebook{
  background: var(--facebook-color);
  border-radius: 25px;
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 0.2em;
  a:hover, a:active, a:visited { 
    color: white !important;
    text-decoration: none;
  }

  svg {
    color: white !important;
  }

  @media screen and (max-width: 768px){
    height: 5vh;
    width: 5vh;
  }

}

#app__social-instagram{
  background-image: var(--instagram-bg);
  border-radius: 25px;
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 0.2em;
  display: flex;
  background-size: contain;
  align-items: center;
  justify-content: center;

  a:hover, a:active, a:visited { 
    color: white !important;
    text-decoration: none !important;
  }

  svg {
    color: white !important;
  }
  
  @media screen and (max-width: 768px){
    height: 5vh;
    width: 5vh;
  }
}

#app__contact-title {
  width: 100%;
  padding-top: 2rem;
}


.form-p-error-contact {
  color: #ae2020;
  font-size: 0.8rem;
  margin: 0;
  width: 100%;
  padding: 0.5rem;
}


.div-container-success-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.new-contact-button {
  height: 3rem;
  width: auto;
  margin: 1rem auto auto auto;
  font-size: 1rem;
  @media screen and (min-width: 1500px) {
    font-size: 1.2rem;
    height: 3.5rem;
  }
}

.h2-contact {
  font-size: 1rem;
  font-weight: 100;

  @media screen and (min-width: 1500px) {
    font-size: 1.5rem;
  }
}