.app__product-view-container {
  height: 100vh;
  width: 100%;
  display: flex;
  z-index: 1000;
  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    overflow: scroll;
  }
}

// SEARCH BAR
.app__productSearchBar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem 8rem;
  background: var(--nav-bar-color);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: fixed;
  z-index: 2000;
  height: 4.5rem;

  @media screen and (max-width: 768px) {
    padding: 0.5rem 2rem;
    height: 4.5rem;
  }
}

.app__productSearchBar-sm-logo {
  display: none;
  justify-content: flex-start;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  background-color: white;
  border-radius: 50%;

  a,
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.app__productSearchBar-logo {
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 769px) {
    display: flex;
  }
}

.app__productSearchBar-bar {
  flex-grow: 2;
  justify-content: center;
  height: 80%;
  display: flex;

  input {
    height: 100%;
    border: 0;
    margin-right: 2rem;
    border-radius: 10px;
    font-family: var(--font-base), "FontAwesome";
    font-size: 1rem;
    font-weight: thin !important;
    padding: 1rem;
  }
}

.app__products-search-bar-desktop {
  display: block;
  width: 50%;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.app__products-search-bar-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin: 0 !important;
    width: 80% !important;
  }
}

.app__product-view-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 85vh;
  padding: 0 8rem;
  margin: auto;
  margin-top: 4.5rem;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 95vw;
    padding: 0%;
    height: 100%;
  }
}

.app__product-view-data-breadcrumbs {
    display: flex;
    height: 10%;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;

    svg {
        font-size: 0.8rem;
        font-weight: 200;
        margin: 0 0.3rem;
    }

    a:visited {
      color: #003380;
      background-color: transparent;
      text-decoration: none;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

.app__product-view-data-inner-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
        padding: 0 0.4rem 2rem;
    }
}

.app__product-view-data-breadcrumbs-mobile{
  display: none;  

  @media screen and (max-width: 768px) {
    display: flex;
    height: 2rem;
    margin: 1rem 0;
  }
}

.app__product-view-data-img {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 4rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.app__product-view-data-img-current {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  * {
    border-radius: 6px;
  }
  
  @media screen and (max-width: 768px) {
    height: 36vh;
    width: 100%;
    padding: 0;
  }
}

.app__product-view-data-img-gallery {
  width: 100%;
  height: 30%;
  padding: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    height: 100%;
    padding: 0 0.1rem;
    border-radius: 6px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    height: 17vh !important;

    *{
      padding: 0;
      width: 100%;
    }

    height: 100%;
  }
}

.app__products-carousel-img {
  width: 100%;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.app__product-view-data-info {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.5rem;
  }
}


.app__product-view-data-info-name{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
}


.app__product-view-data-info-brand{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0.8rem;
    font-size: 1rem;
}

.app__product-view-data-info-description {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1rem;
}

.app__product-view-data-info-state {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1rem;
    
    b {
      padding: 0.2rem 1rem;
      background-color: var(--nav-bar-color);
      border-radius: 50px;
      color: white;
    }
}

.app__product-view-data-info-more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    padding: 1rem;
    background: #E5E5E5;
    border-radius: 5px;
}


.app__product-view-data-info-more-text{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    font-size: 1rem;
}

.app__product-view-data-info-more-button {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 0.4rem;
    padding: 0 1rem;
    border-radius: 5px;
    background-color: var(--orange);
}

.app__products-view-carousel-desktop {
    display: block;
    height: 20vh;
    width: 100%;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  
  .app__products-carousel-mobile {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
      width: 100%;
      height: 15vh;
      margin-top: 1rem;
    }
  }
  
  .app__products-view-carousel-img {
    width: 100%;
    object-fit: cover;
  }


.alert-danger {
  text-align: center;
  width: 30vw;
  height: 2rem;
  margin: auto;
  color: #ae2020;
  font-size: 1.2rem;
  
  @media screen and (max-width: 768px) {
    width: 90vw;
    font-size: 1.2rem;
  } 
}