/* Slideshow */

.app__about-slideshow {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    height: 60%;
    padding-top: 1rem;
  }
  }

.app__about-slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
  height: 85%;
}

.app__about-slide {
  display: inline-block;

  height: 100%;
  width: 100%;
}

/* Buttons */

.app__about-slideshowDots {
  text-align: center;
}

.app__about-slideshowDot {
  display: inline-block;
  height: 0.9em;
  width: 0.9rem;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;

  @media screen and (max-height: 700px) {
    height: 0.7rem;
    width: 0.7rem;
  }
}

.app__about-slideshowDot.active {
  background-color: var(--nav-bar-color);
}

#app__about-slide-item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    height: 100%;

    span {
        z-index: 1;
        font-size: 2rem;
        font-weight: 700;
      }
  
      p {
        z-index: 1;
        font-size: 1.1rem;
        padding: 2rem 0.5rem;
        word-break: break-all;
        white-space: normal;
      }
}

.app__about-list-img {
    @media screen and (max-width: 768px) {
      display: block;
      z-index: 0;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
      position: absolute;
      opacity: 0.5;
    }
  }
  